































































































































































































































































































































































































import { Component } from 'vue-property-decorator'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import DetailPage from '@/components/search-results/DetailPage.vue'
import SelectedCompetences from '@/components/kode/SelectedCompetences.vue'
import { CompetenceDetail, CompetenceImprovement } from '@/store/kode/types'
import { Action, Getter } from 'vuex-class'
import { GET_TALENT_PROFILE_PREVIEW, GET_TALENT_VIEW } from '@/store/student/actions'
import { GET_JOB_AD, GET_TALENT_VIEW_BY_JOB_AD } from '@/store/jobAd/actions'
import { GenericRequest, Qualification, SimpleServerResponse, TalentProfileViewType, User } from '@/store/types'
import { KeyValue } from '@/store/commonFormInput/types'
import Enums from '@/common/Enums'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import TargetCompetenceProfileResult from '@/components/kode/TargetCompetenceProfileResult.vue'
import { ApplicationModel, InquiryDto } from '@/store/inquiry/types'
import ProfilePicture from '@/components/common/ProfilePicture.vue'
import InquiryModal from '@/components/forms/InquiryModal.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { ACCEPT_DECLINE, INQUIRE_TALENT_CANCEL } from '@/store/inquiry/actions'
import {
  ACCEPT,
  DECLINE,
  getAcceptDeclinePositiveFeedback, getCompanyInquiryConfirmationHeadingForStudent,
  getTalentRequestConfirmationExplanationForCompany,
  sendTalentInquiry
} from '@/common/inquiry'
import { JobAdSpecs, JobAdType } from '@/store/jobAd/types'
import { sortArray } from '@/common/globals'
import { prepareCvDownload } from '@/common/cvLayout'
import MetaComponent from '@/common/MetaComponent.vue'

@Component({
  components: { ProfilePicture, TargetCompetenceProfileResult, SearchResult, ResultsPage, SelectedCompetences, DetailPage, LoadingSpinner, InquiryModal, ConfirmationModal }
})
export default class TalentProfile extends MetaComponent {
  @Action(GET_TALENT_VIEW)
  public getTalentView: (id: string) => Promise<TalentProfileViewType>

  @Action(GET_TALENT_PROFILE_PREVIEW)
  public getTalentProfilePreview: () => Promise<TalentProfileViewType>

  @Action(GET_TALENT_VIEW_BY_JOB_AD)
  public getTalentViewByJobAd: (dto: GenericRequest) => Promise<TalentProfileViewType>

  @Action(INQUIRE_TALENT_CANCEL)
  inquireTalentCancel: (requestDto: GenericRequest) => Promise<SimpleServerResponse>

  @Action(GET_JOB_AD)
  getJobAd: (jobAdSpec: JobAdSpecs) => Promise<JobAdType>

  @Action(ACCEPT_DECLINE)
  public acceptOrDeclineApplication: (dto: GenericRequest) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  talentId = ''
  jobAdId = ''
  inquiryButton: string | null | undefined = ''
  inquiryId: string | undefined = undefined
  cvLayout: string | null = null

  errorMessage = ''
  showErrorMessage = false

  loadingCounter = 0

  talent: TalentProfileViewType = {}
  jobAdQualifications: Qualification[] | undefined = []
  strictQualificationMatching: boolean | undefined = undefined
  kodeBridgeResult: Array<CompetenceDetail> = []

  competencesCollapse = false
  qualificationsCollapse = false
  desiredAction = ''
  modalTitle = ''
  modalAlert = ''
  routeRole: string | (string | null)[] = ''

  get improvementData (): CompetenceImprovement {
    return { baseCompetenceToImprove: this.talent.baseCompetence, competencesToImprove: this.talent.competences, jobFamily: this.talent.jobFamily }
  }

  get cvTypes (): KeyValue[] {
    return Enums.CV_TYPES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('student.CV_TYPES.' + key).toString()
      }
    })
  }

  get openInquiries (): InquiryDto[] {
    if (this.talent.inquiries) return this.talent.inquiries.filter(el => el.inquiryStatus === Enums.OPEN_INQUIRY_TO_TALENT)
    else return []
  }

  get acceptedInquiries (): InquiryDto[] {
    if (this.talent.inquiries) return this.talent.inquiries.filter(el => el.inquiryStatus === Enums.INQUIRY_ACCEPTED_BY_TALENT)
    else return []
  }

  get deniedInquiries (): InquiryDto[] {
    if (this.talent.inquiries) return this.talent.inquiries.filter(el => el.inquiryStatus === Enums.INQUIRY_DENIED_BY_TALENT)
    else return []
  }

  get CANCEL (): string {
    return Enums.OPEN_INQUIRY_TO_TALENT
  }

  get WAS_DENIED (): string {
    return Enums.INQUIRY_DENIED_BY_TALENT
  }

  get NOT_ALLOWED (): string {
    return Enums.NOT_ALLOWED
  }

  get OPEN_TO_COMPANY (): string {
    return Enums.OPEN_INQUIRY_TO_COMPANY
  }

  get ACCEPTED_BY_COMPANY (): string {
    return Enums.INQUIRY_ACCEPTED_BY_COMPANY
  }

  get ACCEPTED_BY_TALENT (): string {
    return Enums.INQUIRY_ACCEPTED_BY_TALENT
  }

  get DECLINED_BY_COMPANY (): string {
    return Enums.INQUIRY_DENIED_BY_COMPANY
  }

  get ACCEPT (): string {
    return ACCEPT
  }

  get DECLINE (): string {
    return DECLINE
  }

  get missingQualifications (): Qualification[] {
    if (this.jobAdQualifications) {
      const missing = this.jobAdQualifications.filter(el => (el.relevance === 'MUSS' || el.relevance === 'SOLL') && (this.talent.qualifications && !this.talent.qualifications.some(talentQuali => talentQuali.synonym === el.synonym)))
      return missing
    } else return []
  }

  openRequestModal (): void {
    this.showErrorMessage = false
    this.$bvModal.show('talent-inquiry-modal')
  }

  sendInquiry (inquiryText: ApplicationModel): void {
    console.log(inquiryText)
    this.setContentLoad(true)
    sendTalentInquiry(
      this,
      this.userGetter.appUserId,
      this.jobAdId, this.talentId,
      inquiryText
    ).then((data: InquiryDto) => {
      this.inquiryButton = data.inquiryStatus
      this.inquiryId = data.inquiryId
    }).catch((error: Error) => {
      this.errorMessage = error.message
      this.showErrorMessage = true
    }).finally(() => {
      this.$router.replace({ query: { inquiryButton: this.inquiryButton, inquiryId: this.inquiryId } })
      this.setContentLoad(false)
    })
  }

  openCancellationConfirmModal (): void {
    this.$bvModal.show('confirmation-modal-cancel-inquiry')
  }

  cancelInquiry (): void {
    if (this.inquiryId !== undefined) {
      this.setContentLoad(true)
      const requestDto: GenericRequest = {
        query: [this.inquiryId],
        params: undefined
      }
      this.inquireTalentCancel(requestDto).then(() => {
        this.inquiryButton = null
        this.inquiryId = undefined
        this.$root.$emit('alert', this.$i18n.t('info.success').toString(), this.$i18n.t('job-ad.matching.inquiry-canceled').toString(), false)
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      }).finally(() => {
        this.$router.replace({ query: { inquiryButton: this.inquiryButton, inquiryId: this.inquiryId } })
        this.setContentLoad(false)
      })
    }
  }

  openApplicationResponseModal (desiredAction: string): void {
    this.modalTitle = getCompanyInquiryConfirmationHeadingForStudent(desiredAction)
    this.modalAlert = getTalentRequestConfirmationExplanationForCompany(desiredAction)
    this.desiredAction = desiredAction
    this.$bvModal.show('confirmation-modal-application')
  }

  applicationResponse (): void {
    if (this.inquiryId !== undefined) {
      const dto: GenericRequest = { query: [], params: undefined }
      if (this.desiredAction === this.ACCEPT) {
        dto.query.splice(0, 1, 'true')
      } else {
        dto.query.splice(0, 1, 'false')
      }
      dto.query[1] = this.inquiryId as string

      this.setContentLoad(true)
      this.acceptOrDeclineApplication(dto).then(() => {
        const message = getAcceptDeclinePositiveFeedback(this.desiredAction)
        if (this.desiredAction === this.ACCEPT) {
          this.inquiryButton = Enums.INQUIRY_ACCEPTED_BY_COMPANY
        } else {
          this.inquiryButton = Enums.INQUIRY_DENIED_BY_COMPANY
        }
        this.$root.$emit('alert', message, '', false)
      }).catch(() => {
        this.$root.$emit('alert', this.$i18n.t('warning.general-error-message').toString(), '', true)
      }).finally(() => {
        this.$router.replace({ query: { inquiryButton: this.inquiryButton, inquiryId: this.inquiryId } })
        this.setContentLoad(false)
      })
    }
  }

  downloadCv (): void {
    const doc = prepareCvDownload(this.cvLayout ? this.cvLayout : '', this.talent)
    window.open(doc.output('bloburl').toString(), '_blank')
  }

  mounted (): void {
    this.talentId = this.$route.params.id
    this.jobAdId = this.$route.params.jobAdId
    if (this.$route.name?.includes('jobad')) {
      this.inquiryButton = typeof this.$route.query.inquiryButton === 'string' ? this.$route.query.inquiryButton : null
      this.inquiryId = typeof this.$route.query.inquiryId === 'string' ? this.$route.query.inquiryId : undefined
    }
    this.cvLayout = this.$route.params.cvLayout
    this.routeRole = this.$route.query.role
    this.loadData()
  }

  loadData (): void {
    this.setContentLoad(true)
    if (this.talentId && this.jobAdId) {
      Promise.all([
        this.getTalentViewByJobAd({ query: [this.jobAdId, this.talentId] }),
        this.getJobAd({ id: this.jobAdId, isOwn: true })
      ]).then(data => {
        this.talent = data[0]
        sortArray(this.talent.qualifications, 'synonym', false, false)

        if (this.talent.kodeBridgeResult && this.loadingCounter < 3) {
          try {
            this.kodeBridgeResult = JSON.parse(this.talent.kodeBridgeResult).competenceDetails
          } catch (e) {
            this.loadingCounter++
            this.loadData()
          }
        } else {
          this.$root.$emit('alert', this.$tc('warning.could-not-load', 1, { what: this.$t('talent-profile.talent-profile') }), '', true, true)
        }

        this.strictQualificationMatching = data[1].strictQualificationMatching
        this.jobAdQualifications = data[1].qualifications
        sortArray(this.jobAdQualifications, 'synonym', false, false)
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      }).finally(() => {
        this.setContentLoad(false)
      })
    } else if (this.talentId) {
      this.getTalentView(this.talentId).then(data => {
        this.talent = data
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      }).finally(() => {
        this.setContentLoad(false)
      })
    } else {
      this.getTalentProfilePreview().then(data => {
        this.talent = data
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      }).finally(() => {
        this.setContentLoad(false)
      })
    }
  }
}
